import React, { useState } from "react"
import { Navigate, Route, useLocation } from "react-router-dom"

import {
  SentryRoutes,
  ProtectedRoute,
  AccountComponent,
  SharedAuth,
  PartnersRoutes,
  Redirect,
} from "../App"
import GettingStarted from "../sections/GettingStarted"
import Logout from "../pages/Logout"
import ResetPasswordConfirm from "../pages/ResetPasswordConfirm"
import Partners from "../pages/partners/Partners"
import Error404 from "../pages/Error404"
import NavLayout from "../pages/_layouts/NavLayout"
import { AuthDataTypes, SignUpDataTypes } from "../types/auth"

export const PartnersRouter = () => {
  const location = useLocation()
  const [authData, setAuthData] = useState<AuthDataTypes | SignUpDataTypes>({})

  const bgClass =
    location.pathname === "/getting-started"
      ? "bg-tree-img"
      : location.pathname === "/pro-sign-up"
        ? "bg-pro-signup-img"
        : ""

  return (
    <SentryRoutes>
      <Route
        element={
          <NavLayout
            bgClass={bgClass}
            partnersNav
            notificationsStatus="success"
            notificationsIsLoading={false}
          />
        }
      >
        <Route
          path="/create-account"
          // Lint ignore: Since this is a one-off Redirect, we don't care about virtual DOM constructor preservation
          element={
            <Navigate replace to={{ ...location, pathname: "/sign-up" }} />
          }
        />

        <Route element={<SharedAuth />}>
          <Route
            path="/sign-up"
            element={
              // we have instances of /sign-up? out in the world so we preserve direct link to sign-up page
              <Navigate
                replace
                to={{ ...location, pathname: "/getting-started" }}
                state={{ authStep: "signUp" }}
              />
            }
          />
          <Route
            path="/login"
            element={
              <Navigate
                replace
                to={{ ...location, pathname: "/getting-started" }}
              />
            }
          />
          <Route
            path="/login"
            element={
              <Navigate
                replace
                to={{ ...location, pathname: "/getting-started" }}
              />
            }
          />
          <Route
            path="/reset-password"
            element={
              <Navigate
                replace
                to={{ ...location, pathname: "/getting-started" }}
                state={{ authStep: "resetPassword" }}
              />
            }
          />
          <Route
            path="/getting-started"
            element={
              <GettingStarted authData={authData} setAuthData={setAuthData} />
            }
          />
        </Route>

        <Route path="/logout" element={<Logout />} />

        <Route
          path="/reset-password-confirm"
          element={<ResetPasswordConfirm />}
        />

        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Navigate replace to="/partners" />} />
          <Route path="/programs" element={<Redirect to="/partners" />} />
          <Route
            path="/programs/:programId"
            element={<Redirect to="/partners" />}
          />

          <Route element={<PartnersRoutes />}>
            <Route
              path="/partners"
              element={
                <AccountComponent component={Partners} allowMissingId={true} />
              }
            />
          </Route>
        </Route>

        <Route path="*" Component={Error404} />
      </Route>
    </SentryRoutes>
  )
}
